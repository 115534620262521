/* .header-tab-menus{
    background: #fff;
    color: var(--dk-blue);
}
.header-tab-menus .item{
    color: #333;
    text-transform: capitalize;
    font-size: 12px;
}
.header-tab-menus .item.active{
    color: #333;
    background-color: #e6e6e6;
    border-top:  0;
    border-bottom: 3px solid var(--dk-blue);
    font-weight: 600;
} */

.row {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
}



.header-tab-menus .item {
    text-transform: capitalize;
    font-size: 12px;
}

.header-tab-menus .item.has-submenu.active {
    padding-right: 24px;
    border-bottom: 0 !important;
}

.header-tab-menus .item.has-submenu.active::after {
    content: '';
    position: absolute;
    right: 5px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #3f51b5;
    top: 12px;
}

.header-tab-submenus {
    overflow: auto;
    white-space: nowrap;
    border-bottom: 1px solid #cccfd1;
    box-shadow: 1px 2px 2px #efefef;
    position: relative;
    padding-left: 0px;
    clear: both;
}

.header-tab-submenus .subemenu-arrow {
    color: var(--dk-blue);
}

.header-tab-submenus .item {
    padding: 5px 0px;
    font-weight: 500;
    color: #333;
    font-size: 10px;
    cursor: pointer;
    text-decoration: none;
    margin: 0 10px;
    border-radius: 0;
    text-transform: uppercase;
    letter-spacing: 0.3px;

}

.header-tab-submenus .item.active {
    border: 1px solid transparent;
    border-radius: 0;
    border-bottom: 1px solid var(--dk-blue);
    font-weight: 700;
    color: var(--dk-blue);
}

.successful-message {
    color: #A7DFC6
}

.menu-tabs .nav-item {
    border: 0;
}

.menu-tabs .nav-link {
    border: 0;
    border-bottom: 1px solid #eee;
    color: #000;
    padding-top: 0;
    font-size: 14px;
}

.menu-tabs .nav-link.active {
    border: 0;
    border-bottom: 2px solid #333;
    color: #000;
    font-weight: 700;
    letter-spacing: 0.3px;
}

.menu-tabs-tab-title {
    text-align: left;
    padding-left: 0;
    font-weight: 600;
    font-size: 16px;
    color: var(--dk-blue);
    border: 0;
    padding: 5px;
}

.menu-type-items-title {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    color: #333;
    border: 0;
    padding: 5px;
}

.gpnt-poupup-tabs {
    display: flex;
    justify-content: start;
    background: var(--dk-blue);
    position: relative;
}

.gpnt-poupup-tabs .nav-item,
.gpnt-poupup-tabs .nav-item:focus-visible {
    outline: none;
}

.gpnt-poupup-tabs .nav-item:first-of-type {
    min-width: 80px;
    position: relative;
}

.gpnt-poupup-tabs .nav-item:first-of-type:after,
.gpnt-poupup-tabs .nav-item:first-of-type:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.gpnt-poupup-tabs .nav-item:first-of-type:after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: #fff;
    border-width: 0px;
    margin-top: -10px;
}

.gpnt-poupup-tabs .nav-item:first-of-type:before {
    border-color: rgba(194, 225, 245, 0);
    border-left-color: #fff;
    border-width: 8px;
    margin-top: -7px;
}

.gpnt-poupup-tabs .nav-item:last-of-type {
    position: absolute;
    right: 0;
    background: transparent;
}

.gpnt-poupup-tabs :nth-child(2) {
    background: var(--dk-blue);
    width: 25px;
    border: 0;
    border-color: transparent;
}

.gpnt-poupup-tabs .nav-item:last-of-type {
    background: #d43f3a;
}

.gpnt-poupup-tabs .nav-item:first-of-type .nav-link.disabled {
    background: transparent;
    border: 0;
    color: #ffffff !important;
    font-weight: 700;
    font-size: 17px;
    letter-spacing: -0.5px;
}

.gpnt-poupup-tabs .nav-item .nav-link {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%;
    color: #fff !important;
    transition: none !important;
    margin-bottom: 0 !important;
}

.gpnt-poupup-tabs .nav-item .nav-link.active {
    color: var(--dk-blue) !important;
    border-color: transparent;
}

.gpnt-poupup-tabs .nav-link:hover,
.gpnt-poupup-tabs .nav-link:focus {
    isolation: unset !important;
    border-color: transparent !important;
    background: #eee;
    color: #000 !important;
}

.gpnt-poupup-tabs .nav-item:last-of-type .nav-link:hover,
.gpnt-poupup-tabs .nav-item:last-of-type .nav-link:focus {
    isolation: unset !important;
    border-color: transparent !important;
    background: transparent !important;
    color: #fff !important;
}

.promotion-box .tab-content {
    padding: 0 0.5rem !important;
}

.gpnt-grid-edit-warning {
    color: #fb8700f5 !important;
}

.hdr-pending-approval {
    color: #fb8700f5 !important;
    font-weight: 700;
    background: #fff;
    text-align: left;
    text-transform: uppercase;
    padding: 8px;
    font-size: 14px;
}

.grid-custom-search-btn {
    height: 28px;
    line-height: 15px;
}

.mb-12x {
    margin-bottom: 12px;
}

#controlled-tab-product-tab-2.nav-link.nav-link.disabled,
#controlled-tab-product-tab-3.nav-link.nav-link.disabled,
#controlled-tab-product-tab-4.nav-link.nav-link.disabled,
#controlled-tab-product-tab-5.nav-link.nav-link.disabled,
#controlled-tab-product-tab-6.nav-link.nav-link.disabled {
    display: none;
}

.product-box #controlled-tab-product-tabpane-0,
.product-box #controlled-tab-product-tabpane-1,
.product-box #controlled-tab-product-tabpane-2,
.product-box #controlled-tab-product-tabpane-3,
.product-box #controlled-tab-product-tabpane-4,
.product-box #controlled-tab-product-tabpane-5 {
    padding: 0 0.5rem !important;
}

.highlight-label-red .form-check-label {
    color: red;
    font-weight: 600;
}

.maxwidth-dialog {
    max-width: 1200px;
}

.chk-group-icon {
    position: absolute;
    left: -4px;
    font-size: 21px;
    bottom: 1px;
    color: #44444470;
}
.chk-group-icon-sd {
    position: absolute;
    left: -4px;
    font-size: 21px;
    bottom: 4px;
    color: #44444470;
}

.chk-group-items {
    padding-left: 2.4rem;

}

.show-toggle-div {
    display: none;

}

.show-toggle-div.visible {
    display: block;
}

.staff-discount-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
}

.sd-dep-name-label {
    width: 115px;
    margin-right: 30px;
}

.sd-dep-dicount {
    width: 90px;
}

.sd-dep-dicount::after {
    content: "%";
}

.sd-subdep-name-label {
    width: 115px;
    margin-right: 12px;
    font-size: 11px;
}

.sd-perc {
    margin-left: 5px;
    font-size: 12px;
    font-weight: 700;
    margin-right: 5px;
}
.grid-back-button .dx-button{
    background: transparent;
    position: relative;
    bottom: 7px;
    border-radius: 100%!important;
    border-color: var(--dk-blue)!important;
   
}
.grid-back-button .dx-button-has-icon .dx-icon{
    width: 12px!important;
    height: 12px!important;
    color: var(--dk-blue)!important;
    font-size: 17px!important;
}
.breadcrumb-item{
    font-size: 17px;
}